<template>
  <div id="user.user.index">
    <b-row class="m-0 p-4">
      <b-col md="3"></b-col>
      <b-col md="6">
        <b-card>
            Username: <br>
          {{profile.username}} <br> <br>
          Email: <br>
          {{profile.email}} <br> <br>
          Company: <br>
          {{profile.company}}
        </b-card>
      </b-col>
      <b-col md="3"></b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "user.user.index",
  computed: {
    ...mapGetters({
      profile: "getProfileForm",
    }),
  },
};
</script>

<style>
</style>